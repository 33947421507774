$wcmc-base: "../../../../../profiles/wcmc/themes/wcmc_base/";

$primary-nav-width: 19%;


#primary-nav .level-1 {
    @include breakpoint($sm) {
        width: 17%;
        &:nth-child(4) {
            width: 19%;
        }
    }
    @include breakpoint($md) {
        width: $primary-nav-width;
    }
    width: $primary-nav-width; //Customize me based on content!
    padding: 8px 2px;
    &:first-child {
        width: 18%;
    }
    
    &:last-child {
        @include breakpoint($sm) {
            width: 28%;
        }
        @include breakpoint($md) {
            width: 24%;
        }
    }
    &:last-child a:hover {
        border-bottom: none;
    }
    
    @include breakpoint($sm) {
        padding: 8px 2px;
    }
}
#drawer-nav .level-2 {
    @include breakpoint($sm) {
        padding-right: 10px;
    }
}
.title-bar {
    font-size: 22px;
}

.brand__lockup img {
    height: 84px;
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

iframe {
    max-width: 600px;
    width: 100%;
}

.select2-container {
    width: 280px !important;
}

.physician {
    @include clearfix();
    margin-bottom: 40px;
    
    .physician-bio a.external-link:after {
        content: '\e80d';
        color: $wcm-bright-orange;
        padding-left: 6px;
        padding-top: 6px;
        font-size: 60%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: none;
        line-height: 1.5;
    }
}

.physician-image {
    padding: 0px 0px 10px 0px;
    @include breakpoint($sm) {
        float: right;
        padding: 30px 0px 20px 40px;
        position: relative;
    }
}

.page-node-27 {
    .pane-node-field-related-articles ul {
        margin-top: 20px;
        
        h2 {
            display: none;
        }
    }
}

.biblio-export {
    @include breakpoint($xs) {
        float: none;
        text-align: inherit;
    }
}

.page-publications, .page-research-and-clinical-trials-publications {
    .site-main {
        margin-left: auto;
        margin-right: auto;
//        padding-left: 20px;
//        padding-right: 20px;
        
        h1.title, .biblio-category-section, #biblio-header {
            padding-left: 20px;
            padding-right: 20px;
        }
        
        #biblio-header {
            a {
                padding-left: 10px;
                padding-right: 10px;
                &:first-child {
                    padding-left: 0;
                }
            }
            img {
                margin-left: -10px;
                padding-right: 10px;
                vertical-align: middle;
            }
        }
        
        @include breakpoint($sm) {
            width: 768px;
        }
        @include breakpoint($md) {
            width: 980px;
        }
        @include breakpoint($lg) {
            width: 1180px;
        }
    }
}

.view-teaser {
    border-bottom: 5px solid $wcm-dark-orange;
    margin-bottom: 15px;
    padding-bottom: 15px;
    
    img.panopoly-image-quarter {
        @include breakpoint($sm) {
            float: right;
            margin-left: 1.5em;
            padding-top: 0;
        }
        max-width: none;
        width: auto;
        float: none;
        margin-bottom: 1.5em;
        padding-top: 0;
    }
    
    &:last-child {
        border-bottom: none;
    }
}

.caption.panopoly-image-quarter {
    max-width: none !important;
    @include breakpoint($sm) {
        max-width: 25% !important;
    }
}

.front {
    div.caption-left {
        @include breakpoint($sm) {
            float: left;
            margin: 10px 10px 10px 0;
        }
        @include breakpoint($md) {
            margin: 20px 40px 20px 0;
        }
    }
}
.front .view-teaser {
    margin-bottom: 0;
    
    .event-date, .news-category {
        padding-left: 10px;
    }
}

.views-exposed-form{
//   padding: 10px 130px;
    margin-bottom: 40px;
    position: relative;
//    border: 1px dotted $link-color;
//    text-align: center;
    
    /*.form-item {
        position: relative;
    }
    label {
        display: block;
    }*/
}

.views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 2em;
}

.views-exposed-form .views-exposed-widget {
//    padding: 0;
//    float: none;
    .views-widget {
        position: relative;
    }
    
}

.pane-node-field-related-articles ul {
    padding: 0 0em;
    list-style: none;
}

.information-sidebar {
    text-align: center;
    h3 {
        text-align: center;
        margin-top: 0;
    }
    .phone{
      text-align: center;
      color: $wcm-red;
      font-size: 22px;
//      line-height: 0;
      padding-bottom: 17px;
      font-weight: 700;
    }
    .address{
      padding-bottom: 30px;
    }
    p a{
//      color: gray;
    }
    
    .wcmc-call-to-action span {
        float: left;
//        margin-right: 5px;
        text-indent: -9999px;
        background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
        background-repeat: no-repeat;
        background-size: 1700%;
        background-position: -30px -30px;
//        background-size: 986px 92px;
        width: 30px;
	    height: 30px;
//        background-position: -265px -31px;
        border: none;
    }
  }

.trial {
  padding: 0px 20px 0;
}

.trial.views-row-even {
  background: $wcm-bg-gray;
  border: 1px dotted #dfdfdf;
  border-width: 1px 0;
}

.trial-name {
  //font-weight: bold;
}
.view-clinical-trials {
    h3 {
        margin-top: 60px;
    }
    
    #edit-sort-bef-combine-wrapper label {
        font-size: inherit;
    }
    #edit-sort-bef-combine {
        .select2-container {
            display: none;
        }
        .form-item .form-item {
            display: inline-block;
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.enrollment-status {
  font-style: italic;
}

.enrollment-status:before {
  content: '* ';
  color: #b31b1b;
}

.no-js {
    .element__hide {
        display: block;
    }
    .element__toggle:after {
        display: none;
    }
}

/* =============================================================================
   ~Homepage
   ========================================================================== */

.front #main {
  padding: 0;
}
.front footer {
    padding-top: 0;
}

.panel-ibd-home {
  background: $wcm-bg-gray;
  @include breakpoint($md) {
    // background: none;
    width: 1140px;
    margin: 0 auto;
  }
}

.panel-ibd-home .sidebar-first {
  background: $wcm-bg-gray;
}

.panel-ibd-home #main-content {
  background: #fff;
}

// Main Panel -- /sites/all/modules/ibd_custom_panes/templates/id-home-pane.tpl.php

.main-home-pane {
    overflow: hidden;
    position: relative;
    img {
        width: 100%;
    }
}

.main-pane-text {
  padding: 15px 0;
  background: rgb(207, 69, 32); /* The Fallback */
  text-align: center;
  @include breakpoint($sm) {
    background: #0875B1;
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(207, 69, 32, 0.9);
    filter: alpha(opacity=93);
  }
  &:hover {
    @include transition(.35s);
    background: rgba(207, 69, 32, 0.7);
    filter: alpha(opacity=70);
  }
}

.main-pane-text h2 {
  font-size: 32px;
  line-height: 1;
  font-weight: normal;
  color: $wcm-yellow;
  margin: 0 auto;
  padding: 0 0 5px;
//  text-transform: uppercase;
  width: 80%;
  @include breakpoint($sm) {
    font-size: 40px;
    width: auto;
    float: none;
    padding: 40px 0;
    margin: 0;
  }
}

.main-pane-text p {
  font-size: 14px;
  color: $wcm-white;
  margin: 0 auto;
  padding: 0;
  width: 80%;
  line-height: 24px;
  @include breakpoint($sm) {
    font-size: 18px;
//    @include box-sizing(border-box);
    line-height: 28px;
    width: auto;
    float: none;
    padding: 0 10%;
    text-align: center;
  }

  @include breakpoint($md) {
    padding: 0 190px;
  }


  a {
    color: $wcm-yellow;
  }
  a:hover {
    color: #fff;
  }
}

.best-hospitals {
  margin: 60px 0 0;
  @include breakpoint($sm) {
      margin: 90px 0 0;
  }
  img {
    width: 70px;
  }
  p {
    font-size: 12px;
    padding-top: 20px;
    line-height: 1.7rem;
//    color: $wcm-yellow; // rgb(132, 178, 209);
  }
  ul {
    width: 320px;
    margin: 0 auto;
    padding: 0;
    @include clearfix();
  }
  li {
    list-style-type: none;
//    float: left;
  }
  a {
//    color: #fff;
    color: $wcm-yellow;
  }
  .trials,
  .services {
    font-size: 18px;
    width: 71px;
    padding: 0px 0 0;
    text-align: center;
    .li{
      list-style-type: none;
    }
  }
  .badge {
    width: 150px;
    padding: 0 10px 0 16px;
    background: none;
  }
}

// Callout Panel

.callout {
    @include transition(.25s);
    position: relative;
    background: $wcm-bright-orange;
    color: #fff;
    margin-bottom: 20px;
    padding: 20px 30px 30px;
    @include breakpoint($sm) {
        padding: 40px 50px 30px;
    }
  text-align: center;
//  font-weight: 100;
  overflow: hidden;
    p {
        font-size: 14px;
        @include breakpoint($sm) {
            font-size: 18px;
        }
    }
  h3 {
//    font-size: 24px;
  }
  a {
    color: #fff;
      &:after {
          color: $wcm-red;
      }
  }
  ul {
    margin: 0;
    padding: 0;
    @include breakpoint($sm) {
      position: relative;
      bottom: -60px;
      @include transition(.25s);
      transform: translate(0,0px);
      -webkit-transform: translate(0, 0px);
      -moz-transform: translate(0,0px);
      -o-transform: translate(0, 0px);
      -ms-transform: translate(0,0px);
    }
  }
  ul li {
    list-style-type: none;
    padding: 0;
    @include breakpoint($sm) {
      font-size: 18px;
      list-style-type: none;
      display: inline;
      border-left: 1px solid #fff;
      padding: 0 0 0 30px;
    }
      a {
          @include breakpoint($sm) {
            color: $wcm-bright-orange;
          }
      }
  }
  ul li:first-child {
    @include breakpoint($sm) {
      padding: 0 30px 0 0;
      border: none;
    }
  }
  &:hover {
//    @include transition(.25s);
    background-color: $wcm-red;
//    padding: 20px 50px 50px;
    padding: 20px 30px 30px;
    @include breakpoint($sm) {
//        padding: 40px 50px 30px;
        padding: 20px 50px 50px;
    }
      a:after {
          color: $wcm-bright-orange;
      }
  }
}

.callout:hover ul {
  @include breakpoint($sm) {
    @include transition(.25s);
    transform: translate(0,-50px);
    -webkit-transform: translate(0,-50px);
    -moz-transform: translate(0,-50px);
    -o-transform: translate(0,-50px);
    -ms-transform: translate(0,-50px);
  }
}

// Make an Appointment

.make-an-appointment {
  text-align: center;
  height: 200px;
  padding-top: 30px;
  h3 {
    font-size: 26px;
  }
    h2 {
        color: $wcm-bright-orange;
    }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    display: block;
//    width: 80%;
      @include breakpoint($sm) {
//          width: 40%;
      }
    margin: 0 auto; // 30%;
    text-align: center;
    /*background-image: url($wcmc-base + 'images/sprites/wc-connect_white--336w@2x.png');
    background-repeat: no-repeat;
    background-position: 39% 32%;
    background-size: 160px 30px;
    background-color: #b31b1b;
    color: #b31b1b;*/
    margin-bottom: 0.4em;
    margin-top: 20px;
//    text-transform: uppercase;
//    font-size: 0.0em;
//    font-weight: 500;
//    border-radius: 8px;
//    line-height: 50px;
  }
  .disclaimer {
    font-size: 14px;
    color: #888;
  }
}

.questions {
  padding: 50px 0;
  text-align: center;
  h3 {
    font-size: 26px;
    margin: 0;
  }
  p {
    font-size: 14px;
    width: 50%;
    margin: 0 auto;
    color: #888;
  }
  ul {
    margin: 0;
    padding: 20px 0 0;
  }
  li {
    list-style-type: none;
    display: inline-block;
  }
  a {
//    display: block;
    margin: 0 0 10px 20px;
//    padding: 10px 30px;
//    background-color: #b31b1b;
//    color: #fff;
//    border-radius: 8px;
//    font-size: 2rem;
//    text-transform: uppercase;
//    font-weight: 100;
  }
  a:hover {
    text-decoration: none;
  }
}

// News Sidebar home page
.sidebar-first
.teaser-title{
  font-size: 15px;
  padding: 10px 10px 0px;
//  padding: 5px 10px 0px;
  line-height: 1.6em;
  // font-weight: 100;
}

// newsworthy pages, dr profile page

// .sidebar-first,
.teaser-title{
// .event-date
  margin-top: 0;
  /*font-size: 23px;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-transform: capitalize;*/
  // float: left;
  // position: absolute;
  // clear: both;
  // a {
  //   color:  #0072b1;
  // }
}

.sidebar-first h2 a {
//  color: $base-color;
  font-weight: 100;
   // padding: 10px 20px 0px;
}

.news-category {
  margin:0;
  padding: 0 0px;
  line-height: 1.4rem;
  a {
//  font-size: 0.8em;
//  color: #BBC4C8;
    &:after {
      content: "\203A\203A"; // nicer looking double arrow
      padding-left:10px;
      font-weight: 100;
      text-decoration: none;
    }
  }
}

.teaser-image img {
  width: 130px;
  overflow: hidden;
  border-radius: 0px;
  float: left;
  padding-right: 20px;
    padding-top: 10px;
  @include breakpoint($sm) {
    float: left;
    padding: 10px 20px 0 0;
  }
  @include breakpoint($md) {
//    width: 240px;
    width: 100%;
    float: none;
    padding: 0;
  }
}

.date-display-single{
//  color: #718188;
  font-size: 0.9em;
  padding: 0px 0px 0px;
}
.panel-twocol
.date-display-single{
  margin: 40px 0px 50px;
//  border: 1px dotted;
    .field.field-name-field-news-subtitle {
    margin-bottom: 20px;
    }
}

.panel-twocol .hero-image {
    @include breakpoint($sm) {
        float: right;
        padding: 0px 0px 0px 20px;
        max-width: 40%;
    }
}

.addthis_toolbox {
    padding: 20px 0px;
}

.field.field-name-field-news-subtitle {
padding: 0px 0px 30px 0px;
text-transform: inherit;
}

.field-name-field-news-category {
//    clear: both;
//    margin: 1em 0;
    h3 {
        margin-top: 0;
    }
//    position: absolute;
//    bottom: 85px;
}