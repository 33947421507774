/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: 19%;
  padding: 8px 2px; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      width: 17%; }
      #primary-nav .level-1:nth-child(4) {
        width: 19%; } }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      width: 19%; } }
  #primary-nav .level-1:first-child {
    width: 18%; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1:last-child {
      width: 28%; } }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1:last-child {
      width: 24%; } }
  #primary-nav .level-1:last-child a:hover {
    border-bottom: none; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      padding: 8px 2px; } }

@media screen and (min-width: 768px) {
  #drawer-nav .level-2 {
    padding-right: 10px; } }

.title-bar {
  font-size: 22px; }

.brand__lockup img {
  height: 84px; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; } }

iframe {
  max-width: 600px;
  width: 100%; }

.select2-container {
  width: 280px !important; }

.physician {
  margin-bottom: 40px; }
  .physician:before, .physician:after {
    content: " ";
    display: table; }
  .physician:after {
    clear: both; }
  .physician .physician-bio a.external-link:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 6px;
    padding-top: 6px;
    font-size: 60%;
    vertical-align: top;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    line-height: 1.5; }

.physician-image {
  padding: 0px 0px 10px 0px; }
  @media screen and (min-width: 768px) {
    .physician-image {
      float: right;
      padding: 30px 0px 20px 40px;
      position: relative; } }

.page-node-27 .pane-node-field-related-articles ul {
  margin-top: 20px; }
  .page-node-27 .pane-node-field-related-articles ul h2 {
    display: none; }

@media screen and (max-width: 767px) {
  .biblio-export {
    float: none;
    text-align: inherit; } }

.page-publications .site-main, .page-research-and-clinical-trials-publications .site-main {
  margin-left: auto;
  margin-right: auto; }
  .page-publications .site-main h1.title, .page-publications .site-main .biblio-category-section, .page-publications .site-main #biblio-header, .page-research-and-clinical-trials-publications .site-main h1.title, .page-research-and-clinical-trials-publications .site-main .biblio-category-section, .page-research-and-clinical-trials-publications .site-main #biblio-header {
    padding-left: 20px;
    padding-right: 20px; }
  .page-publications .site-main #biblio-header a, .page-research-and-clinical-trials-publications .site-main #biblio-header a {
    padding-left: 10px;
    padding-right: 10px; }
    .page-publications .site-main #biblio-header a:first-child, .page-research-and-clinical-trials-publications .site-main #biblio-header a:first-child {
      padding-left: 0; }
  .page-publications .site-main #biblio-header img, .page-research-and-clinical-trials-publications .site-main #biblio-header img {
    margin-left: -10px;
    padding-right: 10px;
    vertical-align: middle; }
  @media screen and (min-width: 768px) {
    .page-publications .site-main, .page-research-and-clinical-trials-publications .site-main {
      width: 768px; } }
  @media screen and (min-width: 992px) {
    .page-publications .site-main, .page-research-and-clinical-trials-publications .site-main {
      width: 980px; } }
  @media screen and (min-width: 1200px) {
    .page-publications .site-main, .page-research-and-clinical-trials-publications .site-main {
      width: 1180px; } }

.view-teaser {
  border-bottom: 5px solid #cf4520;
  margin-bottom: 15px;
  padding-bottom: 15px; }
  .view-teaser img.panopoly-image-quarter {
    max-width: none;
    width: auto;
    float: none;
    margin-bottom: 1.5em;
    padding-top: 0; }
    @media screen and (min-width: 768px) {
      .view-teaser img.panopoly-image-quarter {
        float: right;
        margin-left: 1.5em;
        padding-top: 0; } }
  .view-teaser:last-child {
    border-bottom: none; }

.caption.panopoly-image-quarter {
  max-width: none !important; }
  @media screen and (min-width: 768px) {
    .caption.panopoly-image-quarter {
      max-width: 25% !important; } }

@media screen and (min-width: 768px) {
  .front div.caption-left {
    float: left;
    margin: 10px 10px 10px 0; } }

@media screen and (min-width: 992px) {
  .front div.caption-left {
    margin: 20px 40px 20px 0; } }

.front .view-teaser {
  margin-bottom: 0; }
  .front .view-teaser .event-date, .front .view-teaser .news-category {
    padding-left: 10px; }

.views-exposed-form {
  margin-bottom: 40px;
  position: relative;
  /*.form-item {
        position: relative;
    }
    label {
        display: block;
    }*/ }

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 2em; }

.views-exposed-form .views-exposed-widget .views-widget {
  position: relative; }

.pane-node-field-related-articles ul {
  padding: 0 0em;
  list-style: none; }

.information-sidebar {
  text-align: center; }
  .information-sidebar h3 {
    text-align: center;
    margin-top: 0; }
  .information-sidebar .phone {
    text-align: center;
    color: #b31b1b;
    font-size: 22px;
    padding-bottom: 17px;
    font-weight: 700; }
  .information-sidebar .address {
    padding-bottom: 30px; }
  .information-sidebar .wcmc-call-to-action span {
    float: left;
    text-indent: -9999px;
    background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
    background-repeat: no-repeat;
    background-size: 1700%;
    background-position: -30px -30px;
    width: 30px;
    height: 30px;
    border: none; }

.trial {
  padding: 0px 20px 0; }

.trial.views-row-even {
  background: #f7f7f7;
  border: 1px dotted #dfdfdf;
  border-width: 1px 0; }

.view-clinical-trials h3 {
  margin-top: 60px; }

.view-clinical-trials #edit-sort-bef-combine-wrapper label {
  font-size: inherit; }

.view-clinical-trials #edit-sort-bef-combine .select2-container {
  display: none; }

.view-clinical-trials #edit-sort-bef-combine .form-item .form-item {
  display: inline-block;
  margin-right: 15px; }
  .view-clinical-trials #edit-sort-bef-combine .form-item .form-item:last-child {
    margin-right: 0; }

.enrollment-status {
  font-style: italic; }

.enrollment-status:before {
  content: '* ';
  color: #b31b1b; }

.no-js .element__hide {
  display: block; }

.no-js .element__toggle:after {
  display: none; }

/* =============================================================================
   ~Homepage
   ========================================================================== */
.front #main {
  padding: 0; }

.front footer {
  padding-top: 0; }

.panel-ibd-home {
  background: #f7f7f7; }
  @media screen and (min-width: 992px) {
    .panel-ibd-home {
      width: 1140px;
      margin: 0 auto; } }

.panel-ibd-home .sidebar-first {
  background: #f7f7f7; }

.panel-ibd-home #main-content {
  background: #fff; }

.main-home-pane {
  overflow: hidden;
  position: relative; }
  .main-home-pane img {
    width: 100%; }

.main-pane-text {
  padding: 15px 0;
  background: #cf4520;
  /* The Fallback */
  text-align: center; }
  @media screen and (min-width: 768px) {
    .main-pane-text {
      background: #0875B1;
      position: absolute;
      top: 0;
      bottom: 0;
      background: rgba(207, 69, 32, 0.9);
      filter: alpha(opacity=93); } }
  .main-pane-text:hover {
    -webkit-transition: 0.35s;
    -o-transition: 0.35s;
    transition: 0.35s;
    background: rgba(207, 69, 32, 0.7);
    filter: alpha(opacity=70); }

.main-pane-text h2 {
  font-size: 32px;
  line-height: 1;
  font-weight: normal;
  color: #ffc72c;
  margin: 0 auto;
  padding: 0 0 5px;
  width: 80%; }
  @media screen and (min-width: 768px) {
    .main-pane-text h2 {
      font-size: 40px;
      width: auto;
      float: none;
      padding: 40px 0;
      margin: 0; } }

.main-pane-text p {
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
  padding: 0;
  width: 80%;
  line-height: 24px; }
  @media screen and (min-width: 768px) {
    .main-pane-text p {
      font-size: 18px;
      line-height: 28px;
      width: auto;
      float: none;
      padding: 0 10%;
      text-align: center; } }
  @media screen and (min-width: 992px) {
    .main-pane-text p {
      padding: 0 190px; } }
  .main-pane-text p a {
    color: #ffc72c; }
  .main-pane-text p a:hover {
    color: #fff; }

.best-hospitals {
  margin: 60px 0 0; }
  @media screen and (min-width: 768px) {
    .best-hospitals {
      margin: 90px 0 0; } }
  .best-hospitals img {
    width: 70px; }
  .best-hospitals p {
    font-size: 12px;
    padding-top: 20px;
    line-height: 1.7rem; }
  .best-hospitals ul {
    width: 320px;
    margin: 0 auto;
    padding: 0; }
    .best-hospitals ul:before, .best-hospitals ul:after {
      content: " ";
      display: table; }
    .best-hospitals ul:after {
      clear: both; }
  .best-hospitals li {
    list-style-type: none; }
  .best-hospitals a {
    color: #ffc72c; }
  .best-hospitals .trials,
  .best-hospitals .services {
    font-size: 18px;
    width: 71px;
    padding: 0px 0 0;
    text-align: center; }
    .best-hospitals .trials .li,
    .best-hospitals .services .li {
      list-style-type: none; }
  .best-hospitals .badge {
    width: 150px;
    padding: 0 10px 0 16px;
    background: none; }

.callout {
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  position: relative;
  background: #e7751d;
  color: #fff;
  margin-bottom: 20px;
  padding: 20px 30px 30px;
  text-align: center;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .callout {
      padding: 40px 50px 30px; } }
  .callout p {
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      .callout p {
        font-size: 18px; } }
  .callout a {
    color: #fff; }
    .callout a:after {
      color: #b31b1b; }
  .callout ul {
    margin: 0;
    padding: 0; }
    @media screen and (min-width: 768px) {
      .callout ul {
        position: relative;
        bottom: -60px;
        -webkit-transition: 0.25s;
        -o-transition: 0.25s;
        transition: 0.25s;
        transform: translate(0, 0px);
        -webkit-transform: translate(0, 0px);
        -moz-transform: translate(0, 0px);
        -o-transform: translate(0, 0px);
        -ms-transform: translate(0, 0px); } }
  .callout ul li {
    list-style-type: none;
    padding: 0; }
    @media screen and (min-width: 768px) {
      .callout ul li {
        font-size: 18px;
        list-style-type: none;
        display: inline;
        border-left: 1px solid #fff;
        padding: 0 0 0 30px; } }
    @media screen and (min-width: 768px) {
      .callout ul li a {
        color: #e7751d; } }
  @media screen and (min-width: 768px) {
    .callout ul li:first-child {
      padding: 0 30px 0 0;
      border: none; } }
  .callout:hover {
    background-color: #b31b1b;
    padding: 20px 30px 30px; }
    @media screen and (min-width: 768px) {
      .callout:hover {
        padding: 20px 50px 50px; } }
    .callout:hover a:after {
      color: #e7751d; }

@media screen and (min-width: 768px) {
  .callout:hover ul {
    -webkit-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
    transform: translate(0, -50px);
    -webkit-transform: translate(0, -50px);
    -moz-transform: translate(0, -50px);
    -o-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px); } }

.make-an-appointment {
  text-align: center;
  height: 200px;
  padding-top: 30px; }
  .make-an-appointment h3 {
    font-size: 26px; }
  .make-an-appointment h2 {
    color: #e7751d; }
  .make-an-appointment p {
    margin: 0;
    padding: 0; }
  .make-an-appointment a {
    display: block;
    margin: 0 auto;
    text-align: center;
    /*background-image: url($wcmc-base + 'images/sprites/wc-connect_white--336w@2x.png');
    background-repeat: no-repeat;
    background-position: 39% 32%;
    background-size: 160px 30px;
    background-color: #b31b1b;
    color: #b31b1b;*/
    margin-bottom: 0.4em;
    margin-top: 20px; }
  .make-an-appointment .disclaimer {
    font-size: 14px;
    color: #888; }

.questions {
  padding: 50px 0;
  text-align: center; }
  .questions h3 {
    font-size: 26px;
    margin: 0; }
  .questions p {
    font-size: 14px;
    width: 50%;
    margin: 0 auto;
    color: #888; }
  .questions ul {
    margin: 0;
    padding: 20px 0 0; }
  .questions li {
    list-style-type: none;
    display: inline-block; }
  .questions a {
    margin: 0 0 10px 20px; }
  .questions a:hover {
    text-decoration: none; }

.sidebar-first
.teaser-title {
  font-size: 15px;
  padding: 10px 10px 0px;
  line-height: 1.6em; }

.teaser-title {
  margin-top: 0;
  /*font-size: 23px;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-transform: capitalize;*/ }

.sidebar-first h2 a {
  font-weight: 100; }

.news-category {
  margin: 0;
  padding: 0 0px;
  line-height: 1.4rem; }
  .news-category a:after {
    content: "\203A\203A";
    padding-left: 10px;
    font-weight: 100;
    text-decoration: none; }

.teaser-image img {
  width: 130px;
  overflow: hidden;
  border-radius: 0px;
  float: left;
  padding-right: 20px;
  padding-top: 10px; }
  @media screen and (min-width: 768px) {
    .teaser-image img {
      float: left;
      padding: 10px 20px 0 0; } }
  @media screen and (min-width: 992px) {
    .teaser-image img {
      width: 100%;
      float: none;
      padding: 0; } }

.date-display-single {
  font-size: 0.9em;
  padding: 0px 0px 0px; }

.panel-twocol
.date-display-single {
  margin: 40px 0px 50px; }
  .panel-twocol
.date-display-single .field.field-name-field-news-subtitle {
    margin-bottom: 20px; }

@media screen and (min-width: 768px) {
  .panel-twocol .hero-image {
    float: right;
    padding: 0px 0px 0px 20px;
    max-width: 40%; } }

.addthis_toolbox {
  padding: 20px 0px; }

.field.field-name-field-news-subtitle {
  padding: 0px 0px 30px 0px;
  text-transform: inherit; }

.field-name-field-news-category h3 {
  margin-top: 0; }
